<div class="row center-xs">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <div class="row" *ngIf="merchant">
        <div class="col-xs padding" *ngIf="is_enabled">
          <div class="row center-xs">
            <app-merchant-logo [merchant]="merchant"></app-merchant-logo>
          </div>
          <div class="row center-xs margin-top-30">
            <div class="col-xs-12">
              <h1>{{ merchant.name }}</h1>
            </div>
            <div class="col-xs-12">
              <p class="bold">Thanks for agreeing to review us, it should take less than 30 seconds. To start, please enter your name &amp; email address below.</p>
            </div>
            <form (ngSubmit)="onSubmit()" #advocateForm="ngForm" class="row col-xs-12" ngNativeValidate>
              <div class="form-group col-xs-12 col-sm-6">
                <md-input-container>
                  <input mdInput [(ngModel)]="review_edge_request.customer.name" name="name" type="text" required id="name" placeholder="Your Name" class="box"/>
                </md-input-container>
              </div>
              <div class="form-group col-xs-12 col-sm-6">
                <md-input-container>
                  <input mdInput [(ngModel)]="review_edge_request.customer.contact" name="email" type="email" required id="email" placeholder="Your Email" class="box"/>
                </md-input-container>
              </div>
              <div *ngIf="error" class="col-xs-12 error-message">{{ error }}</div>
              <app-button-group buttonCount="1" class="col-xs-12 margin-top-20">
                <button type="submit" md-raised-button disableRipple="true" color="primary">Continue</button>
              </app-button-group>
            </form>
          </div>
        </div>
        <div class="col-xs-12 padding text-center" *ngIf="! is_enabled">
          <p *ngIf="redirect">Redirecting…</p>
          <p class="bold" *ngIf="! redirect">{{ merchant.name }} has disabled the ability to review.</p>
        </div>
      </div>
    </div>
  </div>
</div>
