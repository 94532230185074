<div *ngIf="provider" class="row center-xs app review-edge">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <div>
        <p>
          <img class="review-site-logo" *ngIf="provider.icon" [src]="provider.icon.medium_url" [alt]="provider.name"/>
        </p>
        <h1>
          {{ provider.text }}
        </h1>
        <app-button-group buttonCount="2">
          <button (click)="trackYes()" md-raised-button disableRipple="true" color="primary" >Yes</button>
          <button (click)="trackNo()" md-button disableRipple="true" color="secondary">No</button>
        </app-button-group>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!provider">
  <app-feedback-form [isPositive]="true"></app-feedback-form>
</div>
