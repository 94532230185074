<form #surveyForm="ngForm" class="survey" (ngSubmit)="next( surveyForm )" [ngStyle]="{ 'background-color': survey_template?.design?.colors?.background }" autocomplete="{{ is_small_device ? 'off': 'on' }}">
  <div class="row row-no-gutter center-xs survey-component-body" [ngClass]="{ 'android-custom-input-focused': is_small_device && isCustomInputFocused() && isAndroidDevice(), 'ios-custom-input-focused': is_small_device && isCustomInputFocused() && isIOSDevice(), 'has-footer': component && component.type != 'exit' }">
    <div class="col-xs-12 col-sm-8 col-lg-6 survey-component-body-content">
      <div *ngIf="component" [ngSwitch]="component.type">
        <div *ngSwitchCase="'intro'">
          <div [ngSwitch]="component.image_type" *ngIf="! isCustomInputFocused()">
            <div *ngSwitchCase="'logo'">
              <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
            </div>
            <div *ngSwitchCase="'cover'">
              <div class="center-xs middle-xs survey-cover-container" *ngIf="merchant?.settings?.review_edge?.cover?.medium_url">
                <img [src]="merchant.settings.review_edge.cover.medium_url" [alt]="merchant.name" class="survey-cover" />
              </div>
            </div>
          </div>
          <div class="row center-xs margin-top-30">
            <div class="col-xs-12">
              <app-survey-component-question class="intro-page" [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
            </div>
            <div class="col-xs-12 margin-top-10 intro-page" *ngIf="component.collect_contact && ! ( reference_code && component.collect_contact_anonymous_only )">
              <div *ngIf="component.name_field != 'disabled' && ( ! is_small_device || custom_input_map['name'] || custom_input_map['name'] == null )" class="form-group">
                <md-input-container class="box">
                  <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['name'] }">
                    <input id="name" mdInput class="survey-input-field hide-placeholder" placeholder="{{ survey_template.translation.mappings.quick_feedback[ 'name' ] }}" [(ngModel)]="model.name" name="name" #name="ngModel" type="text" [required]="component.name_field == 'required'" (focus)="onCustomInputFocus( 'name' )" (blur)="onBlur.next( 'name' )" whiteSpaceValidator/>
                    <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['name']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                  </div>
                </md-input-container>
                <div *ngIf="surveyForm.submitted && name.errors && (name.pristine || name.touched)" class="validation-error">
                  <div [hidden]="! name.errors.required && ! name.errors.whitespace">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                  <div class="validation-arrow"></div>
                </div>
              </div>
              <div *ngIf="component.email_field != 'disabled' && ( ! is_small_device || custom_input_map['email'] || custom_input_map['email'] == null )" class="form-group">
                <md-input-container class="box">
                  <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['email'] }">
                    <input id="email" mdInput class="survey-input-field hide-placeholder" placeholder="Email" [(ngModel)]="model.email" name="email" #email="ngModel" type="email" (focus)="onCustomInputFocus( 'email' )" (blur)="onBlur.next( 'email' )" [required]="component.email_field == 'required'" [pattern]="patterns.email"/>
                    <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['email']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                  </div>
                </md-input-container>
                <div *ngIf="surveyForm.submitted && email.errors && (email.pristine || email.touched)" class="validation-error">
                  <div [hidden]="! email.errors.required">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                  <div [hidden]="! email.errors.pattern">{{ survey_template.translation.mappings.quick_feedback[ 'invalid_email' ] }}</div>
                  <div class="validation-arrow"></div>
                </div>
              </div>
              <div *ngIf="component.phone_field != 'disabled' && ( ! is_small_device || custom_input_map['mobile'] || custom_input_map['mobile'] == null )" class="form-group">
                <md-input-container class="box">
                  <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['mobile'] }">
                    <input id="mobile" mdInput class="survey-input-field hide-placeholder" placeholder="{{ survey_template.translation.mappings.quick_feedback[ 'phone' ] }}" [(ngModel)]="model.phone" name="phone" #phone="ngModel" type="tel" (focus)="onCustomInputFocus( 'mobile' )" (blur)="onBlur.next( 'mobile' )" [required]="component.phone_field == 'required'" [pattern]="patterns.phone"/>
                    <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['mobile']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                  </div>
                </md-input-container>
                <div *ngIf="surveyForm.submitted && phone.errors && (phone.pristine || phone.touched)" class="validation-error">
                  <div [hidden]="! phone.errors.required">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                  <div [hidden]="! phone.errors.pattern">{{ survey_template.translation.mappings.quick_feedback[ 'invalid_phone' ] }}</div>
                  <div class="validation-arrow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'sentiment'">
          <div class="row">
            <div class="col-xs-12">
              <app-survey-component-question [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
              <div [ngSwitch]="component.sentiment_type">
                <div *ngSwitchCase="'emotion'">
                  <div class="row center-xs margin-top-30">
                    <div class="col-xs-12">
                      <div class="row">
                        <div class="col-xs-4" (click)="setValue( 0 )">
                          <md-icon svgIcon="icon-face-negative" class="survey-emotion" [ngClass]="{ 'active': model.answer === 0 }" [ngStyle]="getEmotionStyles( 0 )"></md-icon>
                        </div>
                        <div class="col-xs-4" (click)="setValue( 50 )">
                          <md-icon svgIcon="icon-face-neutral" class="survey-emotion" [ngClass]="{ 'active': model.answer === 50 }" [ngStyle]="getEmotionStyles( 50 )"></md-icon>
                        </div>
                        <div class="col-xs-4" (click)="setValue( 100 )">
                          <md-icon svgIcon="icon-face-positive" class="survey-emotion" [ngClass]="{ 'active': model.answer === 100 }" [ngStyle]="getEmotionStyles( 100 )"></md-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'rating'">
                  <div class="row margin-top-30">
                    <div class="col-xs-12">
                      <div class="row center-xs">
                        <div class="col-xs-2" (click)="setValue( 0 )">
                          <md-icon svgIcon="icon-star" class="survey-star survey-star-1" [ngClass]="{ 'active': model.answer >= 0, 'hover': hover_index >= 1, 'unfill-star': hover_index < 1 && hover_index > 0 }" [ngStyle]="getRatingStyles( 0 )" (mouseover)="addHoverEffect( 1 )" (mouseout)="removeHoverEffect( 1 )"></md-icon>
                        </div>
                        <div class="col-xs-2" (click)="setValue( 25 )">
                          <md-icon svgIcon="icon-star" class="survey-star survey-star-2" [ngClass]=" { 'active': model.answer >= 25, 'hover': hover_index >= 2, 'unfill-star': hover_index < 2 && hover_index > 0 }" [ngStyle]="getRatingStyles( 25 )" (mouseover)="addHoverEffect( 2 )" (mouseout)="removeHoverEffect( 2 )"></md-icon>
                        </div>
                        <div class="col-xs-2" (click)="setValue( 50 )">
                          <md-icon svgIcon="icon-star" class="survey-star survey-star-3" [ngClass]="{ 'active': model.answer >= 50, 'hover': hover_index >= 3, 'unfill-star': hover_index < 3 && hover_index > 0 }" [ngStyle]="getRatingStyles( 50 )" (mouseover)="addHoverEffect( 3 )" (mouseout)="removeHoverEffect( 3 )"></md-icon>
                        </div>
                        <div class="col-xs-2" (click)="setValue( 75 )">
                          <md-icon svgIcon="icon-star" class="survey-star survey-star-4" [ngClass]="{ 'active': model.answer >= 75, 'hover': hover_index >= 4, 'unfill-star': hover_index < 4 && hover_index > 0 }" [ngStyle]="getRatingStyles( 75 )" (mouseover)="addHoverEffect( 4 )" (mouseout)="removeHoverEffect( 4 )"></md-icon>
                        </div>
                        <div class="col-xs-2" (click)="setValue( 100 )">
                          <md-icon svgIcon="icon-star" class="survey-star survey-star-5" [ngClass]="{ 'active': model.answer >= 100, 'hover': hover_index >= 5, 'unfill-star': hover_index < 5 && hover_index > 0 }" [ngStyle]="getRatingStyles( 100 )" (mouseover)="addHoverEffect( 5 )" (mouseout)="removeHoverEffect( 5 )"></md-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'yes_no'">
                  <div *ngIf="component.yes_primary" class="row center-xs margin-top-20">
                    <div class="col-xs-12 margin-bottom-20">
                      <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( true )" [disabled]="is_processing" md-button disableRipple="true" [ngStyle]="getPrimaryYesButtonStyles()">{{ component.yes_label || survey_template.translation.mappings.quick_feedback[ 'yes' ] }}</button>
                    </div>
                    <div class="col-xs-12">
                      <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( false )" [disabled]="is_processing" md-button disableRipple="true" [ngStyle]="getSeconaryNoButtonStyles()">{{ component.no_label || survey_template.translation.mappings.quick_feedback[ 'no' ] }}</button>
                    </div>
                  </div>
                  <div *ngIf="!component.yes_primary" class="row center-xs margin-top-20">
                    <div class="col-xs-12 col-sm-6">
                      <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( false )" [disabled]="is_processing" md-button disableRipple="true" [ngStyle]="getYesNoButtonStyles()">{{ component.no_label || survey_template.translation.mappings.quick_feedback[ 'no' ] }}</button>
                    </div>
                    <div class="col-xs-12 col-sm-6 margin-bottom-20 first-xs last-sm">
                      <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( true )" [disabled]="is_processing" md-button disableRipple="true" [ngStyle]="getYesNoButtonStyles()">{{ component.yes_label || survey_template.translation.mappings.quick_feedback[ 'yes' ] }}</button>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'nps'">
                  <div class="row center-xs">
                    <div class="col-xs-12">
                      <app-survey-linear-scale [colors]="survey_template.design?.colors" [component]="component" [translation]="survey_template.translation" [(model)]="model.answer" (modelChange)="setValue($event)"></app-survey-linear-scale>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'multi_choice'">
          <div class="row center-xs">
            <div class="col-xs-12">
              <app-survey-component-question [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
            </div>
          </div>
          <div class="row center-xs">
            <div class="col-xs-12">
              <!-- @todo are multiple change bindings required here? -->
              <app-survey-multi-choice [colors]="survey_template.design?.colors" [component]="component" [translation]="survey_template.translation" [(model)]="model.answer" [(text)]="model.other_field_text" (modelChange)="setMultiChoiceValue($event)" (onNext)="nextCustomInputAction( surveyForm )" (onFocus)="onCustomInputFocus( 'question' )" (onBlur)="onCustomInputBlur( 'question' )"></app-survey-multi-choice>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'short_answer'">
          <div class="row center-xs">
            <div class="col-xs-12">
              <app-survey-component-question [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
            </div>
          </div>
          <div class="row center-xs">
            <div class="col-xs-12">
              <div [ngSwitch]="component.data_type" class="form-group">
                <div *ngSwitchCase="'text'">
                  <md-input-container class="box hide-mat-placeholder">
                    <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['question'] }">
                      <input mdInput class="survey-input-field" [(ngModel)]="model.answer" placeholder="{{ survey_template.translation.mappings.quick_feedback[ 'enter_answer' ] }}" name="short" #short="ngModel" [required]="component.required" (focus)="onCustomInputFocus( 'question' )" (blur)="onBlur.next( 'question' )" whiteSpaceValidator/>
                      <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['question']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                    </div>
                  </md-input-container>
                  <div *ngIf="surveyForm.submitted && short.errors && (short.pristine || short.touched)" class="validation-error">
                    <div [hidden]="! short.errors.required && ! short.errors.whitespace">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                    <div class="validation-arrow"></div>
                  </div>
                </div>
                <div *ngSwitchCase="'number'">
                  <md-input-container class="box hide-mat-placeholder">
                    <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['question'] }">
                      <input mdInput class="survey-input-field" type="number" [(ngModel)]="model.answer" name="short" #short="ngModel" [required]="component.required" (focus)="onCustomInputFocus( 'question' )" (blur)="onBlur.next( 'question' )"/>
                      <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['question']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                    </div>
                  </md-input-container>
                  <div *ngIf="surveyForm.submitted && short.errors && (short.pristine || short.touched)" class="validation-error">
                    <div [hidden]="! short.errors.required">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                    <div class="validation-arrow"></div>
                  </div>
                </div>
                <div *ngSwitchCase="'email'">
                  <md-input-container class="box hide-mat-placeholder">
                    <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['question'] }">
                      <input mdInput class="survey-input-field" type="email" [(ngModel)]="model.answer" name="short" #short="ngModel" [required]="component.required" [pattern]="patterns.email" (focus)="onCustomInputFocus( 'question' )" (blur)="onBlur.next( 'question' )"/>
                      <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['question']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                    </div>
                  </md-input-container>
                  <div *ngIf="surveyForm.submitted && short.errors && (short.pristine || short.touched)" class="validation-error">
                    <div [hidden]="! short.errors.required">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                    <div [hidden]="! short.errors.pattern">{{ survey_template.translation.mappings.quick_feedback[ 'invalid_email' ] }}</div>
                    <div class="validation-arrow"></div>
                  </div>
                </div>
                <div *ngSwitchCase="'phone'">
                  <md-input-container class="box hide-mat-placeholder">
                    <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['question'] }">
                      <input mdInput class="survey-input-field" type="tel" [(ngModel)]="model.answer" name="short" #short="ngModel" [required]="component.required" [pattern]="patterns.phone" (focus)="onCustomInputFocus( 'question' )" (blur)="onBlur.next( 'question' )"/>
                      <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['question']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                    </div>
                  </md-input-container>
                  <div *ngIf="surveyForm.submitted && short.errors && (short.pristine || short.touched)" class="validation-error">
                    <div [hidden]="! short.errors.required">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                    <div [hidden]="! short.errors.pattern">{{ survey_template.translation.mappings.quick_feedback[ 'invalid_phone' ] }}</div>
                    <div class="validation-arrow"></div>
                  </div>
                </div>
                <div *ngSwitchCase="'date'" class="survey-date-picker">
                  <my-date-picker name="short" placeholder="{{ survey_template.translation.mappings.quick_feedback[ 'select_date' ] }}" [(ngModel)]="model.answer" [required]="component.required"></my-date-picker>
                  <div *ngIf="surveyForm.submitted && ! model.answer && component.required" class="validation-error">
                    <div>{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                    <div class="validation-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'paragraph'" [ngClass]="{ 'ios-textarea-focused': is_small_device && isCustomInputFocused() && isIOSDevice() }">
          <div class="row center-xs">
            <div class="col-xs-12 ">
              <app-survey-component-question [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
            </div>
          </div>
          <div class="row center-xs">
            <div class="col-xs-12 form-group">
              <md-input-container class="box hide-mat-placeholder">
                <div [ngClass]="{ 'custom-input': is_small_device && custom_input_map['question'] }">
                  <textarea mdInput class="survey-textarea-field" [(ngModel)]="model.answer" rows="8" placeholder="{{ survey_template.translation.mappings.quick_feedback[ 'enter_answer' ] }}" name="paragraph" #paragraph="ngModel" [required]="component.required" (focus)="onCustomInputFocus( 'question' )" (blur)="onCustomInputBlur( 'question' )"></textarea>
                  <button md-button disableRipple="true" type="button" (click)="nextCustomInputAction( surveyForm )" *ngIf="is_small_device && custom_input_map['question']" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getCustomInputButtonLabel() }}</button>
                </div>
              </md-input-container>
              <div *ngIf="surveyForm.submitted && paragraph?.errors && (paragraph?.pristine || paragraph?.touched)" class="validation-error textarea-error">
                <div *ngIf="paragraph.errors.required">{{ survey_template.translation.mappings.quick_feedback[ 'required_field' ] }}</div>
                <div class="validation-arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'scale'">
          <div class="row">
            <div class="col-xs-12">
              <app-survey-component-question [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <app-survey-linear-scale [colors]="survey_template.design?.colors" [component]="component" [translation]="survey_template.translation" [(model)]="model.answer" (modelChange)="setValue($event)"></app-survey-linear-scale>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'yes_no'">
          <div class="row center-xs">
            <div class="col-xs-12">
              <app-survey-component-question [colors]="survey_template.design?.colors" [component]="component"></app-survey-component-question>
            </div>
          </div>
          <div *ngIf="component.yes_primary">
            <div class="row center-xs margin-top-20">
              <div class="col-xs-12">
                <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( true )" md-button disableRipple="true" [ngStyle]="getPrimaryYesButtonStyles()">{{ component.yes_label || survey_template.translation.mappings.quick_feedback[ 'yes' ] }}</button>
              </div>
            </div>
            <div class="row center-xs margin-top-20">
              <div class="col-xs-12">
                <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( false )" md-button disableRipple="true" [ngStyle]="getSeconaryNoButtonStyles()">{{ component.no_label || survey_template.translation.mappings.quick_feedback[ 'no' ] }}</button>
              </div>
            </div>
          </div>
          <div *ngIf="!component.yes_primary" class="row center-xs margin-top-20">
            <div class="col-xs-12 col-sm-6">
              <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( false )" md-button disableRipple="true" [ngStyle]="getYesNoButtonStyles()">{{ component.no_label || survey_template.translation.mappings.quick_feedback[ 'no' ] }}</button>
            </div>
            <div class="col-xs-12 col-sm-6 margin-bottom-20 first-xs last-sm">
              <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( true )" md-button disableRipple="true" [ngStyle]="getYesNoButtonStyles()">{{ component.yes_label || survey_template.translation.mappings.quick_feedback[ 'yes' ] }}</button>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'review_triage'">
          <app-survey-review-triage [colors]="survey_template.design?.colors" [component]="component" [translation]="survey_template.translation" [merchant]="merchant" [review]="review" [form]="surveyForm" (reviewChange)="updateReview($event)" [(model)]="model.answer" (modelChange)="updateModel($event)" (onNext)="nextCustomInputAction( surveyForm )" (onFocus)="onCustomInputFocus( 'question' )" (onBlur)="onCustomInputBlur( 'question' )"></app-survey-review-triage>
        </div>
        <div *ngSwitchCase="'exit'">
          <app-survey-exit [colors]="survey_template.design?.colors" [component]="component" [merchant]="merchant"></app-survey-exit>
        </div>
      </div>
      <div *ngIf="error" class="validation-question-error">
        <div class="validation-question-error-message">
          <i class="material-icons ng-scope" ng-switch-when="error">error</i> {{ error.message }}
        </div>
      </div>
      <div class="powered-by-onelocal" *ngIf="! merchant.settings.review_edge.hide_onelocal_branding && ! isCustomInputFocused()">
        <a href="{{ onelocal_home_url }}">
          <md-icon svgIcon="{{ powered_by_onelocal_logo }}" class="powered-by-onelocal__logo"></md-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="row row-no-gutter center-xs middle-xs survey-component-footer" *ngIf="component && component.type != 'exit' && ! isCustomInputFocused()" [ngStyle]="getFooterStyles()">
    <div class="col-xs-12 col-sm-8 col-lg-6" *ngIf="component.type != 'exit'">
      <div class="row between-xs middle-xs">
        <div class="col-xs-4 col-lg-3 survey-back-arrow-container" (click)="back()">
          <i class="material-icons survey-back-arrow" *ngIf="component_ids.length && previous_component?.type != 'intro'" [ngStyle]="{ 'color': survey_template.design?.colors?.text }">keyboard_arrow_left</i>
        </div>
        <div class="col-xs-4 col-lg-3">
          <button type="submit" md-button disableRipple="true" class="survey-next-button" [disabled]="is_processing" *ngIf="component?.type != 'review_triage' || review?.type != 'online_review'" [ngStyle]="{ 'background-color': survey_template.design?.colors?.button_background, 'color': survey_template.design?.colors?.button_text }">{{ getButtonLabel() }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
