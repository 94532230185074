<div class="row center-xs app review-edge">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <div *ngIf="!is_loading">
        <div *ngIf="!is_submitted">
          <form (ngSubmit)="onSubmit()" #feedbackForm="ngForm" ngNativeValidate>
            <app-merchant-logo [merchant]="merchant"></app-merchant-logo>
            <h1>{{ is_positive ? 'Thanks for agreeing to recommend us!' : 'How can we improve?' }}</h1>

            <div class="form-group">
              <label for="feedback-comment">Please provide your feedback below.</label>
              <textarea [(ngModel)]="model.comment" name="comment" required id="feedback-comment" rows="8" cols="10" placeholder="Comments (please be specific)"></textarea>
            </div>

            <div *ngIf="is_positive" class="checkbox margin-bottom-30">
              <label class="font-size-12">
                <input type="checkbox" [(ngModel)]="model.consent_to_share" name="consent">I consent to my comments and feedback potentially being published on your website or in other marketing material
              </label>
            </div>

            <app-button-group buttonCount="1">
              <button type="submit" md-raised-button disableRipple="true" color="primary">Send</button>
            </app-button-group>
          </form>
        </div>
        <div *ngIf="is_submitted">
          <div>
            <app-merchant-logo [merchant]="merchant"></app-merchant-logo>
            <h1>Thanks for submitting your feedback!</h1>
            <p>Your opinions and comments are very important to us and we read every message that we receive.<br>Our goal is to improve our service any way we can, and we appreciate you taking the time to fill out our feedback form.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
