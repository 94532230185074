<div class="survey" [ngStyle]="{ 'background-color': survey_template?.design?.colors?.background }">
  <div class="row row-no-gutter center-xs survey-component-body">
    <div class="col-xs-12 col-sm-10 col-md-8 survey-component-body-content">
      <div class="box" *ngIf="! is_loading">
        <div *ngIf="type">
          <div [ngSwitch]="type">
            <div *ngSwitchCase="'negative'">
              <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
              <div class="margin-top-30">
                <h1 [ngStyle]="{ 'color': survey_template?.design?.colors?.title }">{{ survey_template.translation.mappings.quick_feedback[ 'thanks_for_submitting_feedback' ] }}</h1>
                <p class="exit-text" [ngStyle]="{ 'color': survey_template?.design?.colors?.text }">{{ survey_template.translation.mappings.quick_feedback[ 'opinions_comments_are_important' ] }}<br>{{ survey_template.translation.mappings.quick_feedback[ 'goal' ] }}</p>
              </div>
            </div>
            <div *ngSwitchDefault class="margin-bottom-30">
              <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
              <div class="margin-top-30">
                <h1 [ngStyle]="{ 'color': survey_template?.design?.colors?.title }">{{ survey_template.translation.mappings.quick_feedback[ 'thanks_for_choosing_us' ] }}</h1>
                <p class="exit-text" [ngStyle]="{ 'color': survey_template?.design?.colors?.text }">{{ survey_template.translation.mappings.quick_feedback[ 'review_at_any_sites' ] }}</p>
              </div>
              <div class="row">
                <div *ngFor="let provider of merchant?.settings?.review_edge?.providers" class="margin-top-20 col-xs-12 col-sm-offset-3 col-sm-6">
                  <md-card class="provider-card" (click)="openProvider( provider )" [ngStyle]="{ 'background-color': survey_template?.design?.colors?.button_background }">
                    <div class="provider-icon">
                      <img [src]="getProviderIcon( provider )" [alt]="getProviderName( provider )"/>
                    </div>
                    <div class="provider-card__name-container">
                      <div class="provider-card__name">
                        <h3 class="provider-card__name-text" [ngStyle]="{ 'color': survey_template?.design?.colors?.button_text }">{{ getProviderName( provider ) }}</h3>
                      </div>
                      <h3 class="provider-card__name-text" [ngStyle]="{ 'color': survey_template?.design?.colors?.button_text }"><span class="provider-card__name-text provider-card__name-text--arrow">❯</span></h3>
                    </div>
                  </md-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="! type">
          <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
          <div class="margin-top-30">
            <h1 [ngStyle]="{ 'color': survey_template?.design?.colors?.title }">{{ survey_template.translation.mappings.quick_feedback[ 'thanks_for_submitting_feedback' ] }}</h1>
            <p class="exit-text" [ngStyle]="{ 'color': survey_template?.design?.colors?.text }">{{ survey_template.translation.mappings.quick_feedback[ 'opinions_comments_are_important' ] }}<br>{{ survey_template.translation.mappings.quick_feedback[ 'goal' ] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
