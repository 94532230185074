<div class="button-group row">
  <div class="col-xs-12">
    <div class="box">
      <ng-content select="[md-raised-button]"></ng-content>
    </div>
  </div>
  <div *ngIf="buttonCount > 1" class="col-xs-12">
    <div class="box">
      <ng-content select="[md-button]"></ng-content>
    </div>
  </div>
</div>
