<!-- ngSwitch is not working for some reason have used ngIf for now -->
<div class="field">
  <div *ngIf="field.type == 'email'">
    <input class="form-control answer" type="email" [(ngModel)]="input" (ngModelChange)="setValue($event)" [required]="! field.optional"/>
  </div>
  <div *ngIf="field.type == 'phone'">
    <input class="form-control answer" type="tel" [(ngModel)]="input" (ngModelChange)="setValue($event)" [required]="! field.optional"/>
  </div>
  <div *ngIf="field.type == 'text'">
    <input class="form-control answer" type="text" [(ngModel)]="input" (ngModelChange)="setValue($event)" [required]="! field.optional"/>
  </div>
  <div *ngIf="field.type == 'boolean'">
    <input class="form-control" type="checkbox" [(ngModel)]="input" (ngModelChange)="setValue($event)"/>
    <span class="answer">{{ field.label }}</span>
  </div>
  <div *ngIf="field.type == 'notes'">
    <textarea class="form-control answer" [(ngModel)]="input" (ngModelChange)="setValue($event)" [required]="! field.optional"></textarea>
  </div>
  <div *ngIf="field.type == 'select'">
    <select class="form-control" [(ngModel)]="input" (ngModelChange)="setValue($event)" [required]="! field.optional">
      <option *ngFor="let option of field.options" [value]="option.value">{{ option.label || option.value }}</option>
    </select>
  </div>
  <div *ngIf="field.type == 'multiselect'">
    <div *ngFor="let option of field.options">
      <input type="checkbox" [(ngModel)]="input[ option.value ]" (ngModelChange)="setValue(input)"/>
      <span class="answer">{{ option?.label || option?.value }}</span>
    </div>
  </div>
</div>
