<div class="row center-xs">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <div *ngIf="request">
        <app-merchant-logo [merchant]="request.merchant"></app-merchant-logo>
        <h1>
          Would you recommend {{ request.merchant.name }}?
        </h1>
        <app-button-group buttonCount="2">
          <button (click)="trackYes()" md-raised-button color="primary" disableRipple="true">Yes</button>
          <button (click)="trackNo()" md-button disableRipple="true">No</button>
        </app-button-group>
      </div>
    </div>
  </div>
</div>
