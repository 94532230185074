<div class="row center-xs">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <div *ngIf="! success">
        <div *ngIf="smartform">
          <form class="smartform" (ngSubmit)="onSubmit()" #smarfForm="ngForm" ngNativeValidate>
            <div class="row">
              <div class="col-xs-12 text-center">
                <h2 class="title">{{ smartform.title || smartform.label }}</h2>
              </div>
              <div class="col-xs-12" *ngIf="smartform.description">
                <p class="description">{{ smartform.description }}</p>
              </div>
              <div *ngFor="let fieldset of smartform.forms[ 0 ].fieldsets | displayFieldset" class="form-group col-xs-12 margin-top-15">
                <label class="control-label question">{{ fieldset.name || fieldset.label }}</label>
                <app-field *ngFor="let field of fieldset.fields" [field]="field" [(model)]="draft"></app-field>
                <small class="sm-text">{{ fieldset.help_text }}</small>
              </div>
              <div class="col-xs-12 text-center" *ngIf="error">
                <div class="error-text">{{ error }}</div>
              </div>
              <div *ngIf="smartform.ask_marketing_opt_in" class="col-xs-12 margin-top-20 marketing">
                <input type="checkbox" name="marketing" [(ngModel)]="draft.opt_in_marketing">
                <span class="question">{{ smartform.opt_in_message || 'I consent to receiving digital marketing communication from ' + smartform.merchant.name  + ' and understand that I can contact ' + smartform.merchant.email + ' if I have any questions regarding my consent &amp; that I may withdraw my consent at any time.' }}</span>
              </div>
              <div class="col-xs-12 margin-top-20">
                <button type="submit" class="cta" md-raised-button disableRipple="true" color="primary" [disabled]="disabled || is_processing">{{ smartform?.cta || 'Submit' }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="success" class="smartform">
        <h3 class="title">{{ success }}</h3>
      </div>
    </div>
  </div>
</div>
