<div class="row full-page center-xs" [ngStyle]="{ 'background-color': survey_template?.design?.colors?.background }">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <app-merchant-logo [merchant]="merchant"></app-merchant-logo>
      <div>
        <h1 class="margin-top-30" [ngStyle]="{ 'color': survey_template?.design?.colors?.title }">{{ survey_template?.translation.mappings.quick_feedback[ 'thanks' ] }}</h1>
      </div>
    </div>
  </div>
</div>
