<div class="survey-linear-column">
  <div class="row start-xs center-sm">
    <div *ngFor="let option of options" class="col-xs-12 col-sm">
      <div class="survey-linear-scale" [ngClass]="{ 'active': input && input[ option.value ] }" (click)="setValue( option.value )" [ngStyle]="getStyles( option.value )">
        <div class="survey-linear-scale-option">{{ option.label }}</div>
      </div>
    </div>
  </div>
  <div class="row survey-linear-scale-labels" >
    <div class="col-xs text-left" *ngIf="component.min_label" [ngStyle]="{ 'color': colors?.text }">{{ component.min_label }}</div>
    <div class="col-xs text-center" *ngIf="component.middle_label" [ngStyle]="{ 'color': colors?.text }">{{ component.middle_label }}</div>
    <div class="col-xs text-right" *ngIf="component.max_label" [ngStyle]="{ 'color': colors?.text }">{{ component.max_label }}</div>
  </div>
</div>
