<div class="row center-xs">
  <div *ngIf="! is_small_device || ! is_custom_input_focused" class="col-xs-12">
    <div *ngFor="let option of component.options">
      <div class="survey-multi-choice-option" [ngClass]="{ 'active': input && input[ option.id ] }" (click)="setValue( option.id )" [ngStyle]="getStyles( option.id )">
        <div class="row row-no-gutter middle-xs">
          <div class="col-xs-offset-1 col-xs-10">{{ option.label }}</div>
          <md-icon class="col-xs-1" *ngIf="input[ option.id ]">check</md-icon>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="component.include_other_choice" class="col-xs-12">
    <div class="survey-multi-choice-option" [ngClass]="{ 'active': input && input[ null ] }" (click)="setValue( null )" [ngStyle]="getStyles( null )">
      <div class="row row-no-gutter middle-xs">
        <div class="col-xs-offset-1 col-xs-10">{{ translation.mappings.quick_feedback[ 'other' ] }}</div>
        <md-icon class="col-xs-1" *ngIf="input[ null ]">check</md-icon>
      </div>
    </div>
  </div>
  <div *ngIf="input[ 'null' ]" class="col-xs-12">
    <div class="form-group">
      <md-input-container class="box hide-mat-placeholder">
        <div [ngClass]="{ 'custom-input': is_small_device && is_custom_input_focused }">
          <input mdInput type="text" class="survey-other-input-field" [ngModel]="text" (ngModelChange)="setText($event)" placeholder="{{ translation.mappings.quick_feedback[ 'enter_answer' ] }}" name="other" (focus)="focus($event)" (blur)="onBlurHandler.next($event)"/>
          <button md-button disableRipple="true" type="button" (click)="next()" *ngIf="is_small_device && is_custom_input_focused" [ngStyle]="{ 'background-color': colors?.button_background, 'color': colors?.button_text }">{{ translation.mappings.quick_feedback[ 'next' ] }}</button>
        </div>
      </md-input-container>
    </div>
  </div>
</div>
