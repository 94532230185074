<div [ngSwitch]="component.exit_type">
  <div *ngSwitchCase="'redirect'">
    <div class="row">
      <div class="col-xs-12">
        <p>Redirecting…</p>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'static'">
    <div [ngSwitch]="component.image_type">
      <div *ngSwitchCase="'logo'">
        <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
      </div>
      <div *ngSwitchCase="'cover'">
        <div class="center-xs middle-xs survey-cover-container" *ngIf="merchant?.settings?.review_edge?.cover?.medium_url">
          <img [src]="merchant.settings.review_edge.cover.medium_url" [alt]="merchant.name" class="survey-cover" />
        </div>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-12 margin-top-30">
        <app-survey-component-question class="exit-page" [colors]="colors" [component]="component"></app-survey-component-question>
      </div>
      <div class="col-xs-6 col-md-4" *ngIf="component.cta_link">
        <button type="button" class="survey-exit-cta" (click)="openCtaLink()" md-button disableRipple="true" [ngStyle]="{ 'background-color': colors?.button_background, 'color': colors?.button_text }">{{ component.cta_link.label }}</button>
      </div>
  </div>
</div>
