<div class="row">
  <div class="col-xs-12">
    <div [ngSwitch]="review.type">
      <div *ngSwitchCase="'online_review'">
        <div class="row center-xs review-site-logo-container" *ngIf="review.provider?.icon">
          <div class="col-xs-12">
            <div class="review-site-logo-box">
              <img class="review-site-logo" [src]="review.provider?.icon.medium_url" [alt]="review.provider?.name"/>
            </div>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12">
            <h1 class="survey-question" [ngStyle]="{ 'color': colors?.title }"> {{ review.provider?.text }}</h1>
          </div>
        </div>
        <div class="row center-xs margin-top-20">
          <div class="col-xs-12 margin-bottom-20">
            <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( true )" md-button disableRipple="true" [ngStyle]="getPrimaryYesButtonStyles()">{{ translation.mappings.quick_feedback[ 'yes' ] }}</button>
          </div>
          <div class="col-xs-12">
            <button class="survey-yes-no-button" type="button" (click)="submitYesNoResponse( false )" md-button disableRipple="true" [ngStyle]="getSeconaryNoButtonStyles()">{{ translation.mappings.quick_feedback[ 'no' ] }}</button>
          </div>
        </div>
        <p class="font-size-12 margin-top-20" *ngIf="is_small_device && review.provider?.type === 'facebook'">
          Please note, Facebook Reviews can only be submitted via Desktop and not the Facebook Mobile app. If you wish to provide a review via your mobile device in future, please consider doing so on this business' Google Business Profile.
        </p>
      </div>
      <div *ngSwitchCase="'positive'" [ngClass]="{ 'ios-textarea-focused': is_small_device && is_custom_input_focused && isIOSDevice() }">
        <div class="row center-xs" *ngIf="! is_small_device || ! is_custom_input_focused">
          <div class="col-xs-12">
            <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
          </div>
        </div>
        <div class="row center-xs margin-top-30">
          <div class="col-xs-12">
            <h1 class="survey-question" [ngStyle]="{ 'color': colors?.title }"> {{ component.positive.label }}</h1>
            <p class="survey-text" [ngStyle]="{ 'color': colors?.text }"  *ngIf="component.positive?.description" [innerHtml]="component.positive.description | autolink"></p>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 form-group">
            <md-input-container class="box hide-mat-placeholder margin-bottom-20">
              <div [ngClass]="{ 'custom-input': is_small_device && is_custom_input_focused }">
                <textarea mdInput class="survey-textarea-field" [(ngModel)]="model.comment" (ngModelChange)="setValue($event)" rows="8" placeholder="{{ translation.mappings.quick_feedback[ 'comments' ] }}" name="testimonial" #testimonial="ngModel" (focus)="focus($event)" (blur)="onBlurHandler.next($event)" [required]="component.positive?.required" appRegisterFormControl [registerForm]="form" [registerModel]="testimonial"></textarea>
                <button md-button disableRipple="true" type="button" (click)="next()" *ngIf="is_small_device && is_custom_input_focused" [ngStyle]="{ 'background-color': colors?.button_background, 'color': colors?.button_text }">{{ translation.mappings.quick_feedback[ 'next' ] }}</button>
              </div>
            </md-input-container>
            <div *ngIf="form.submitted && testimonial.invalid" class="validation-error textarea-error">
              <div [hidden]="! testimonial.errors.required">{{ translation.mappings.quick_feedback[ 'required_field' ] }}</div>
              <div class="validation-arrow"></div>
            </div>
            <md-checkbox [(ngModel)]="model.consent_sharing" *ngIf="component.positive.ask_consent_sharing && ! is_custom_input_focused">{{ translation.mappings.quick_feedback[ 'comments_consent' ] }}</md-checkbox>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'negative'" [ngClass]="{ 'ios-textarea-focused': is_small_device && is_custom_input_focused && isIOSDevice() }">
        <div class="row center-xs">
          <div class="col-xs-12" *ngIf="! is_small_device || ! is_custom_input_focused">
            <app-merchant-logo [merchant]="merchant" class="survey-logo"></app-merchant-logo>
          </div>
        </div>
        <div class="row center-xs margin-top-30">
          <div class="col-xs-12">
            <h1 class="survey-question" [ngStyle]="{ 'color': colors?.title }">{{ component.negative.label }}</h1>
            <p class="survey-text" [ngStyle]="{ 'color': colors?.text }" *ngIf="component.negative?.description" [innerHtml]="component.negative.description | autolink"></p>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 form-group">
            <md-input-container class="box hide-mat-placeholder">
              <div [ngClass]="{ 'custom-input': is_small_device && is_custom_input_focused }">
                <textarea mdInput class="survey-textarea-field" [(ngModel)]="model.comment" (ngModelChange)="setValue($event)" rows="8" placeholder="{{ translation.mappings.quick_feedback[ 'comments' ] }}" name="comment" #comment="ngModel" (focus)="focus($event)" (blur)="onBlurHandler.next($event)" [required]="component.negative?.required" appRegisterFormControl [registerForm]="form" [registerModel]="comment"></textarea>
                <button md-button disableRipple="true" type="button" (click)="next()" *ngIf="is_small_device && is_custom_input_focused" [ngStyle]="{ 'background-color': colors?.button_background, 'color': colors?.button_text }">Next</button>
              </div>
            </md-input-container>
            <div *ngIf="form.submitted && comment.invalid" class="validation-error textarea-error">
              <div [hidden]="! comment.errors.required">{{ translation.mappings.quick_feedback[ 'required_field' ] }}</div>
              <div class="validation-arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
