<div class="row center-xs">
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="box">
      <div *ngIf="!is_loading">
        <div *ngIf="! merchant">
          <div>
            <h1>Thanks for submitting your feedback!</h1>
            <p>Your opinions and comments are very important to us and we read every message that we receive.<br>Our goal is to improve our service any way we can, and we appreciate you taking the time to fill out our feedback form.</p>
          </div>
        </div>
        <div *ngIf="merchant" class="margin-bottom-30">
          <app-merchant-logo [merchant]="merchant"></app-merchant-logo>
          <h1>Thanks for choosing us!</h1>
          <p>You can review us at any of the sites below:</p>
          <div *ngFor="let provider of merchant?.settings?.review_edge?.providers" class="margin-top-20">
            <md-card class="provider-card" (click)="openProvider( provider )">
              <div class="provider-icon">
                <img [src]="getProviderIcon( provider )" [alt]="getProviderName( provider )"/>
              </div>
              <div class="provider-card__name-container">
                <div class="provider-card__name">
                  <h3 class="provider-card__name-text">{{ getProviderName( provider ) }}</h3>
                </div>
                <h3 class="provider-card__name-text"><span class="provider-card__name-text provider-card__name-text--arrow">❯</span></h3>
              </div>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
